export const ServiceStatus: Readonly<{ [p: string]: string }> = Object.freeze({
  PUBLISHED: 'published',
  ASSIGNED: 'assigned',
  CONCLUDED: 'concluded',
  DRAFT: 'draft',
});

export enum ServiceType {
  MOBILE = 'mobile_service',
  OFFICE = 'office_service',
}

export enum ServiceTypeSearch {
  MOBILE = 'mobile',
  OFFICE = 'office',
}
